<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col :style="userData == 'admin' || userType == 'Account Assistant' ? '' : 'display: none'" md="3">
            <b-form-group>
              <label>Sales By</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="userData == 'admin' ? 'required' : ''"
                name="sales By"
              >
                <v-select
                  placeholder="None"
                  v-model="selecttype"
                  :disabled="ifTreeUser ? true : false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  @input="selecttypee($event)"
                  label="name"
                  :options="[
                    'Employee',
                    'ChannelPartner',
                    'Franchise',
                    'Direct',
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="selecttype == 'Employee' ? '' : 'display: none'"
            md="3"
          >
            <b-form-group>
              <label>Select Employee</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  userData == 'employee' || selecttype == 'Employee'
                    ? 'required'
                    : ''
                "
                name="Employee"
              >
                <v-select
                  placeholder="None"
                  v-model="selectedTeam"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="teamOption"
                  @input="userInfo($event)"
                >
                <template #option="{ name, profile_image, username ,surname }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ name }} {{surname}}</span><span>({{ username }})</span>
                </template>
              </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :style="userData == 'employee' ? '' : 'display: none'" md="3">
            <b-form-group>
              <label>Employee</label>
              <v-select
              placeholder="None"
              v-model="selectedTeam"
              disabled
              label="name"
            />
            </b-form-group>
          </b-col>
          <b-col
            :style="
              selecttype == 'ChannelPartner' || userData == 'channelpartner'
                ? ''
                : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Select Channel Partner</label>
              <v-select
                placeholder="None"
                v-model="selectedTeam"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="CPOption"
                @input="userInfo($event)"
              >
              <template #option="{ name, profile_image, username ,surname }">
                <b-avatar :src="getprofileImage(profile_image)" />
                <span class="font-weight-bolder"> {{ name }} {{surname}}</span><span>({{ username }})</span>
              </template>
            </v-select>
            </b-form-group>
          </b-col>

          <b-col
            :style="
              selecttype == 'Franchise' || userData == 'franchise'
                ? ''
                : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Select Franchise</label>
              <v-select
                placeholder="None"
                v-model="selectedTeam"
                :disabled="userData == 'franchise' || ifTreeUser ? true : false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="FranchiseOption"
                @input="userInfo($event)"
              >
              <template #option="{ name, profile_image, username ,surname }">
                <b-avatar :src="getprofileImage(profile_image)" />
                <span class="font-weight-bolder"> {{ name }} {{surname}}</span><span>({{ username }})</span>
              </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col :style="userData == 'franchise' ? '' : 'display: none'" md="3">
            <b-form-group>
              <label>Franchise</label>
              <v-select
              placeholder="None"
              v-model="selectedTeam"
              disabled
              label="name"
            />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Booking Date</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Booking Date"
              >
                <flat-pickr
                  v-model="bookingDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    minDate: userData == 'admin' ? '' : 'today',
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col md="12" v-if="userDetails">
            <b-row>
              <b-col md="3">
                <label>Email</label>
                <b-form-input disabled v-model="userDetails.email"
              /></b-col>
              <b-col md="3">
                <label>Phone No</label>
                <b-form-input disabled v-model="userDetails.mobile"
              /></b-col>
              <b-col md="3">
                <label>Username</label>
                <b-form-input disabled v-model="userDetails.username"
              /></b-col>
              <b-col md="3">
                <label>Photo :</label>
                <b-media-aside>
                  <b-link>
                    <b-img
                      rounded
                      :src="
                        userDetails.profile_image
                          ? userDetails.profile_image
                          : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                      "
                      height="80"
                    />
                  </b-link> </b-media-aside
              ></b-col>

            </b-row>
          </b-col>
          <b-col md="12">
            <b-card-code title="Application Form" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Select Project</label>
                  <label style="color: red !important">*</label>


                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="project"
                    >
              <v-select
                placeholder="None"
                v-model="selectedProject"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="projectname"
                :options="projectOption"
                @input="getPlot($event.id)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Suggested Plot No</label>
                  <label style="color: red !important">*</label>
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="plot"
                      >
                    <v-select
                      placeholder="None"
                      multiple
                      v-model="selectedPlot"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="plotOption"
                      @input="handleMultiPlot($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
            </b-form-group>
          </b-col>
           <b-col md="3">
              <b-form-group>
                <label>Booking Type </label>
                <v-select
                  placeholder="None"
                  @input="handelClickBooking($event)"
                  v-model="bookingtype"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="bookingOptions"
                />
              </b-form-group>
            </b-col>
             <b-col md="3">
              <b-form-group>
                <label>Offer</label>
                <v-select placeholder="None" v-model="offer" label="name" :options="offerOptions" />
              </b-form-group>
            </b-col>

          <b-col
            md="12"
            v-if="visible"
            class="d-flex align-items-center"
            style="overflow: auto"
          >
            <ImageMapView
              :value="phaseImage"
              :projectId="projectId"
              :plotOption="plotOption"
              :plots="plotObj"
              @input="(zones) => change({ zones })"
            />

          </b-col>
          <b-col md="12" v-for="(plot, index) in multiPlots" :key="index">
            <b-row>
              <b-col md="12">
                <b-card-code :title="'Plot No : ' + plot.plotno" no-body />
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Plot Rate (Per Sq. {{ plot.unitname }})</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="PlotRate"
                  >
                    <b-form-input
                      v-model="plot.plotrate"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Plot Rate"
                      :disabled="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Plot Area(Sq. Yard)</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="PlotArea(Sq. Yard)"
                  >
                    <b-form-input
                      v-model="plot.plotareasqyard"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Plot Area(Sq. Yard)"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Plot Area(Sq.Feet)</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="PlotArea(Sq.Feet)"
                  >
                    <b-form-input
                      v-model="plot.plotareasqfeet"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Plot Area(Sq.Feet)"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="userData == 'admin'" md="3">
                <b-form-group>
                  <label>Discount</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Discount"
                  >
                    <b-form-input
                      v-model="plot.discount"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Discount "
                      @input="discountChange($event, index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Remarks</label>
                  <b-form-input
                    v-model="plot.disremarks"
                    placeholder="Enter remarks"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Plot Value</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="plotvalue"
                  >
                    <b-form-input
                      v-model="plot.plotvalue"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Plot Value"
                      disabled
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Total Payment</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="plotvalue"
                  >
                    <b-form-input
                      v-model="plot.totalpayment"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Plot Value"
                      disabled
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-card-code title="Booking Details" no-body />
          </b-col>
          <b-col
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment'
                ? ''
                : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Booking Min Value/Amount in %</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment'
                    ? 'required'
                    : ''
                "
                name="Booking Min Value %"
              >
                <b-form-input
                  v-model="bookingminvalueper"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Booking Min Value in per"
                  @input="clickMinValuePer"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment'
                ? ''
                : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Booking Min Value/Amount</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment'
                    ? 'required'
                    : ''
                "
                name="Booking Min Value"
              >
                <b-form-input
                  v-model="bookingminvalue"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Booking Min Value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment'
                ? ''
                : 'display: none'
            "
          >
            <b-form-group>
              <label>Agreement Amount in %</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment'
                    ? 'required'
                    : ''
                "
                name="Agreement %"
              >
                <b-form-input
                  v-model="agreementper"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Agreement Amount in %"
                  @input="clickAgreementPer()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment'
                ? ''
                : 'display: none'
            "
          >
            <b-form-group>
              <label>Agreement Amount</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment'
                    ? 'required'
                    : ''
                "
                name="agreement"
              >
                <b-form-input
                  v-model="agreement"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Agreement Amount"
                  @input="clickAgreement()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Payment Terms Days</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PaymentTerms
              Days"
              >
                <b-form-input
                  v-model="paymenttermday"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Payment Terms Days"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Payment Type</label>
              <v-select
                placeholder="None"
                v-model="selectedPaymentType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                @input="calcFirstpaymentMulti"
                :options="['Down Payment', 'Token']"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Payment Through</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Payment Through"
              >
                <v-select
                  placeholder="None"
                  v-model="selectedPaymentThrough"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="paymentThroughOption"
                  @input="handleSelectPayment"
                /><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Select Bank</label>
                <label style="color: red !important">*</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Bank"
                >
                  <v-select
                    v-model="bank"
                    placeholder="None"
                    label="name"
                    :options="bankLedgerOption"
                    @input="handleClickBank($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <b-col md="12"></b-col>
                <b-col md="4" :style="selectedPaymentThrough == 'By UPI' ? 'display:flex' : 'display: none'" class="flex-column align-items-center">
                  <label>Qr Code</label>
                  <div>
                    <img :src="qrcode" :width='300' @click="handleOpenImage(qrcode)"/>
                  </div>
                  <!-- <attachment :data="qrcode" /> -->
                </b-col>
                <b-col :md='selectedPaymentThrough == "By UPI" ? 8 : 12' class="mb-1" :style="bank && bank.action ? '' : 'display: none'">
                  <b-row class="d-flex justify-content-start bankDetails flex-wrap">
                    <b-col :md="selectedPaymentThrough == 'By UPI' ? 6 : 3" sm="6" class="bankDetailsChild" :style="bank.beneficiaryname ? '' : 'display: none'">
                        <h5><b>Beneficiary Name:</b></h5>
                        <span>{{ bank.beneficiaryname }}</span>
                      </b-col>
                    <b-col :md="selectedPaymentThrough == 'By UPI' ? 6 : 3" sm="6" class="bankDetailsChild" :style="bank.accountno ? '' : 'display: none'">
                      <h5><b>Account No:</b></h5>
                      <span>{{ bank.accountno }}</span>
                    </b-col>
                    <b-col :md="selectedPaymentThrough == 'By UPI' ? 6 : 3" sm="6" class="bankDetailsChild" :style="bank.bankbranch ? '' : 'display: none'">
                      <h5><b>Branch Name:</b></h5>
                      <span>{{ bank.bankbranch }}</span>
                    </b-col>
                    <b-col :md="selectedPaymentThrough == 'By UPI' ? 6 : 3" sm="6" class="bankDetailsChild" :style="bank.ifsccode ? '' : 'display: none'">
                      <h5><b>IFSC Code:</b></h5>
                      <span>{{ bank.ifsccode }}</span>
                    </b-col>
                  </b-row>
                  </b-col>
          <b-col md="12">
            <b-card-code title="Payment Details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bank Name"
              >
                <b-form-input
                  v-model="bankName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Bank Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Branch Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch Name"
              >
                <b-form-input
                  v-model="bankbranch"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Branch Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Reference Number</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Reference Number"
              >
                <b-form-input
                  v-model="refno"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Reference Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Upload Receipt</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Upload Receipt "
              >
                <b-form-file
                  v-model="bankreceipt"
                  placeholder="Select Document"
                  drop-placeholder="Drop file here..."
                  @input="handleMultiFileChange($event, 'sales', id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="bankreceipt" />
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Date"
              >
                <flat-pickr
                  v-model="bankdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="noChequeField">
            <b-form-group>
              <label>Remarks</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Remarks"
              >
                <b-form-textarea
                  id="Remarks"
                  v-model="bankremarks"
                  :state="errors.length > 0 ? false : null"
                  rows="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Cheque No</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Cheque No"
              >
                <b-form-input
                  v-model="chequeNo"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Cheque No"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Bank Name"
              >
                <b-form-input
                  v-model="bankName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Bank Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Branch Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch Name"
              >
                <b-form-input
                  v-model="bankbranch"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Branch Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Cheque Date:</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Cheque Date"
              >
                <flat-pickr
                  v-model="chequedate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="chequeField">
            <b-form-group>
              <label>Status</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Status"
              >
                <v-select
                  placeholder="None"
                  v-model="status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="['Pending', 'Bounce', 'Clear']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-2">
            <div class="w-100 mb-1 font-weight-bold" v-if="selectedProject">
              Project : <b-badge variant="success"> {{ selectedProject.projectname }} </b-badge>
            </div>
                <table class="table table-bordered" style="min-width: 10cm">
                  <thead class="text">
                    <tr>
                      <th>Sr. No.</th>
                      <th>Plot No.</th>
                      <th>Discount</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, id) in multiPlots" :key="id">
                      <td> {{ id + 1}}</td>
                      <td> {{ item.plotno}}</td>
                      <td>{{ item.discount }}</td>
                      <td>{{ parseFloat(item.totalpayment).toLocaleString('en-IN') }}</td>
                    </tr>
                  </tbody>
                </table>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Payment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Total Payment"
              >
                <b-form-input
                  v-model="totalPayment"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Total Payment"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>First Payment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="firstPaymentRules"
                name="First Payment"
              >
                <b-form-input
                  v-model="firstpayment"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter First Payment"
                  @input="calcFirstpaymentMulti"
                />
                <small class="text-danger">{{ errorMsg }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remaining Payment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Remaining Payment"
              >
                <b-form-input
                  v-model="remainingPayment"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Remaining Payment"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" v-for="(plot, index) in multiPlots" :key="index" v-if=" bookingtype && bookingtype !== 'Full Payment' ">
            <b-row>
              <b-col md="12">
                <b-card-code :title="'Installment for plot no : ' + plot.plotno" no-body />
              </b-col>

              <b-col v-if="bookingtype == 'EMI'" md="3">
              <!-- <b-col md="3"> -->
              <b-form-group>
                <label>Installment start date</label>
                <label style="color: red !important">*</label>

                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Installment start date"
                >
                  <flat-pickr
                    v-model="plot.installmentsdate"
                    class="form-control"
                    :config="{
                      dateFormat: 'd/m/Y',
                      defaultDate: new Date(),
                    }"
                    style="background-color: transparent"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              </b-col>
              <b-col v-if="bookingtype == 'EMI'" md="3">
                <!-- <b-col md="3"> -->
                <b-form-group>
                  <label>No. of Installment</label>
                  <label style="color: red !important">*</label>

                  <validation-provider #default="{ errors }" :rules="bookingtype == 'EMI' ? 'required' : ''" name="No. of Installment">
                      <v-select
                      label="month"
                      placeholder="None"
                      v-model="plot.noofemi"
                      :options="emiOptions"
                      @input="handleInstallment($event, 'noInstallment', index)"
                    />
                      <!-- <b-form-input v-model="noInstallment"
                    placeholder="Enter No. of Installment" @input="handleInstallment($event, 'noInstallment')" /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
              </b-col>
             <b-col :style="bookingtype == 'EMI' && plot.noofemi && plot.noofemi.rate ? '' : 'display: none'"  md="3">
              <!-- <b-col md="3"> -->
                <b-form-group>
                  <label>Interest Rate (%)</label>
                    <b-form-input v-model="plot.emirate"
                      placeholder="Enter No. of Installment" disabled />
                </b-form-group>
              </b-col>
              <b-col v-if="bookingtype == 'EMI'" md="3">
                <!-- <b-col md="3"> -->
                <b-form-group>
                  <label>Gap between months</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Gap between months"
                  >
                    <b-form-input
                      v-model="plot.monthgap"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Gap between months"
                      @input="handlemonthgap($event, 'monthgap', index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="bookingtype == 'EMI'" md="3">
                <!-- <b-col md="3"> -->
                <b-form-group>
                  <label>Installment Amount</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Installment Amount"
                  >
                    <b-form-input
                      v-model="plot.installmentamt"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Installment Amount"
                      @input="
                        handleinstallmentamt($event, 'installmentamt', index)
                        "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="bookingtype == 'EMI' || bookingtype == 'Part Payment'"
                md="12"
              >
                <!-- <b-col md="12"> -->
                <table class="table table-bordered">
                  <thead class="text">
                    <tr>
                      <th></th>
                      <th>Installment date</th>
                      <th>Installment Amount</th>
                      <th>Remarks</th>
                      <th
                        :style="bookingtype == 'Part Payment' ? '' : 'display:none'
                          "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(installment, installIndex) in plot.installments"
                      :key="installIndex"
                    >
                      <td>{{ installIndex + 1 }}</td>
                      <td>
                        <flat-pickr
                          v-model="installment.installment_date"
                          class="form-control"
                          :config="{
                            dateFormat: 'd/m/Y',
                            defaultDate: new Date(),
                          }"
                          style="background-color: transparent"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model="installment.installment_amount"
                          placeholder="Enter Installment Amount"
                        />
                      </td>
                      <td>
                        <b-form-input
                          v-model="installment.remarks"
                          placeholder="Enter Installment Remarks"
                        />
                      </td>
                      <td
                        :style="bookingtype == 'Part Payment' ? '' : 'display:none'
                          "
                      >
                        <b-button
                          variant="outline-danger"
                          class="mt-0"
                          @click="deleteRow(index, installIndex)"
                        >
                          <feather-icon icon="TrashIcon" class="" />
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-button
                  :style="bookingtype == 'Part Payment' ? '' : 'display:none'"
                  variant="primary"
                  class="mt-1"
                  @click="addRow(index)"
                >
                  Add more
                </b-button>
              </b-col>
              </b-row>
          </b-col>


          <b-col md="12">
            <b-card-code title="Application Name" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Title</label>
              <v-select
                placeholder="None"
                v-model="selectedtitle"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Shri.', 'Smt.', 'Ms.', 'Mrs.', 'Mr.']"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Customer Name</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Customer Name"
                  >
                    <v-select
                      v-model="cfirstname"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customerOption"
                      label="name"
                      @input="getCustomerDetails($event)"
                      placeholder="None"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <label></label>
              <label></label>
              <div class="d-flex align-items-center" style="height: 82px">
                <b-button variant="primary" size="sm" @click="handlePopUp">
                  +
                </b-button>
              </div>
            </b-row>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Address</label>
              <b-form-textarea id="Address" v-model="address" rows="1" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Pin Code</label>
              <b-form-input
                disabled
                v-model="pincode"
                maxlength="6"
                @input="clickPincode($event)"
                type="number"
                placeholder="Enter Pin Code"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <b-form-input disabled v-model="city" placeholder="Enter City" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>

              <b-form-input disabled v-model="area" placeholder="Enter Area" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>

              <b-form-input
                disabled
                v-model="state"
                placeholder="Enter State"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Country</label>
              <b-form-input v-model="country" placeholder="Enter Country" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>DOB</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="DOB"
              >
                <flat-pickr
                  v-model="dateofbirth"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile No.</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Mobile No"
              >
                <b-form-input
                  disabled
                  v-model="mobileNo"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Mobile No."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email ID</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Email ID"
              >
                <b-form-input
                  disabled
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Email ID"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <b-form-input
                v-model="adharno"
                maxlength="14"
                @input="validateAadharClick($event)"
                placeholder="Enter Aadhar No"
              />
              <small v-if="adharnoValidate == false" class="text-danger"
                >Enter Valid Aadhar No</small
              >
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input
                v-model="panno"
                maxlength="10"
                @input="validatePanClick($event)"
                placeholder="Enter PAN No"
              />
              <small v-if="pannoValidate == false" class="text-danger"
                >Enter Valid PAN No</small
              >
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Nominee</label>
              <b-form-input v-model="nominee" placeholder="Enter Nominee" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Relationship</label>
              <b-form-input
                v-model="nomineerelationship"
                placeholder="Enter Nominee Relationship"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Phone</label>
              <b-form-input
                v-model="nomineephone"
                maxlength="10"
                placeholder="Enter Nominee Phone"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee DOB</label>
              <flat-pickr
                v-model="nomineedob"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="ifEdit ? '' : 'display: none'">
            <b-form-group>
              <label>Cancel Sales</label>
              <v-select
                placeholder="None"
                v-model="status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="status == 'Yes' ? '' : 'display: none'">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="remarks" placeholder="Enter remarks" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label style="font-size: 16px">Send:&nbsp;</label>
              <b-form-checkbox v-model="sendemail" inline>
                Email
              </b-form-checkbox>
              <b-form-checkbox v-model="sendsms" inline> SMS </b-form-checkbox>
              <b-form-checkbox v-model="sendwhatsapp" inline>
                Whatsapp
              </b-form-checkbox>

            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-2" v-if="cfirstname && cfirstname.id ">
            <h3>Customer Information</h3>
          </b-col>

          <b-col md='12' v-if="cfirstname && cfirstname.id">
          <b-row v-for="(item,id) in extraCustomers" :key="id">
            <div class="d-flex justify-content-end w-100" style="border-bottom:1px solid rgb(128, 128, 128, 0.2);margin-bottom:20px">
          <b-button
            variant="outline-danger"
            class="mt-0 p-0"
            @click="removeRow(id)"
            style="width:30px; height:30px;"
          >
            <feather-icon icon="TrashIcon" class="" />
          </b-button>
            </div>
            <b-col md="3">
              <b-form-group>
                <label>Customer Name</label>
                <v-select v-model="item.customer"
                :options="customerOption" label="name" placeholder="None" @input="handleExtraCustomer($event , item)" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Birth Date</label>
                <p>{{item.birthdate}}</p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Mobile</label>
                <p>{{item.mobile}}</p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Email</label>
                <p>{{item.email}}</p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Aadhar</label>
                <p>{{item.aadharcardno}}</p>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <label>Pan No</label>
                <p>{{item.panno}}</p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Nominee Name</label>
                <p>{{item.nomineename}}</p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Nomine Relation</label>
                <p>{{item.nomineerelation}}</p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Nominee Birth Date</label>
                <p>{{item.nomineedob}}</p>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Nominee Mobile</label>
                <p>{{item.nomineephone}}</p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-button variant="primary" @click="addcsRow()" class="mb-1 mt-1" >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add More</span>
              </b-button>
            </b-col>
          </b-row>
         </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              :disabled="flag || submitDisable"
              @click.prevent="submitForm"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
            <b-button
              v-if="$route.params.id && userData == 'admin' && status == 'Yes'"
              :disabled="this.active == 0"
              class="ml-1"
              variant="danger"
              @click="handleApprove"
              >Cancel
            </b-button>
          </b-col>
        </b-row>
        <b-modal
          id="modal-form"
          v-model="popUpVisible"
          size="lg"
          cancel-variant="outline-secondary"
          ok-title="Submit"
          cancel-title="Close"
          title="Add Customer"
          hide-footer
        >
          <add-customer
            :fromSales="true"
            :selectedUser="selectedTeam"
            :closePopUp="closePopUp"
            :getCustomer="getCustomer"
          />
        </b-modal>
      </b-form>
        <b-modal
          id="modal-otp"
          v-model="otpModal"
          size="md"
          cancel-variant="outline-secondary"
          ok-title="Submit"
          cancel-title="Close"
          title="OTP Verification"
          hide-footer
        >
        <b-row>
          <b-col md="12">
        <b-form-group>
          <label>OTP Code</label>
          <label style="color: red !important">*</label>

          <validation-provider
            #default="{ errors }"
            rules="required"
            name="OTP Code"
          >
            <b-form-input
              v-model="otp"
              type="number"
              :state="errors.length > 0 ? false : null"
              placeholder="Enter OTP"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

        <b-col cols="12">
          <b-button
            variant="primary"
            type="submit"
            :disabled="flag || submitDisable"
            @click.prevent="submitForm"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-if="$route.params.id && userData == 'admin' && status == 'Yes'"
            :disabled="this.active == 0"
            class="ml-1"
            variant="danger"
            @click="handleApprove"
            >Cancel
          </b-button>
        </b-col>
      </b-row>

        </b-modal>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AddCustomer from "../customer/AddCustomer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BImg,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
  BFormCheckbox,
  BMedia,
  BMediaAside,
    BBadge,

  BMediaBody,
  BLink,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Attachment from "../../../components/Attechment.vue";
import image from "../../../assets/images/pages/no-image.png";
import ImageMapView from "../../../components/ImageMapView.vue";

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    AddCustomer,
    flatPickr,
    BFormCheckbox,
    PinchScrollZoom,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    ImageMapView,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BBadge,
    BFormFile,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BAvatar,
  },
  data() {
    return {
      otpModal:false,
      otp:'',
      plotObj:{},
      payment: "",
      remarks: "",
      status: "",
      active: 1,
      visible: false,
      phaseImage: {
        uuid: 3721,
        source: image,
      },
      bank: "",
      bankbranch: "",
      bankdate: moment(new Date()).format("DD/MM/yyyy"),
      bankreceipt: "",
      bankremarks: "",
      bookingtype: "",
      bookingminvalue: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      selectedTeam: "",
      projectId: "",
      teamOption: [],
      CPOption: [],
      FranchiseOption: [],
      selectedProject: "",
      projectOption: [],
      selectedPhase: "",
      phaseOption: [],
      selectedPlot: "",
      plotOption: [],
      refno: "",
      selectedPaymentType: "",
      selectedPaymentThrough: "",
      paymentThroughOption: [
        "By Cheque",
        "By RTGS",
        "By NEFT",
        "By ECS",
        "Debit/Credit Card",
        "Net Banking",
        "By UPI",
        "Other",
      ],
      bookingDate: moment(new Date()).format("DD/MM/yyyy"),
      plotRate: "",
      plotAreaYard: "",
      plotAreaFeet: "",
      discount: "",
      plotvalue: "",
      totalPayment: "",
      firstpayment: "",
      chequeField: false,
      chequeNo: "",
      bankName: "",
      chequedate: moment(new Date()).format("DD/MM/yyyy"),
      status: "",
      firstPaymentRules: "required|min_value:0",
      remainingPayment: "",
      selectedtitle: "",
      nomineerelationship: "",
      qrcode: "",
      ctitleOption: [
        { name: "Shri." },
        { name: "Smt." },
        { name: "Ms." },
        { name: "Mrs." },
        { name: "Mr." },
      ],
      installmentsdate: new Date(),
      noofinstallment: 1,
      monthgap: "",
      installmentamt: "",
      selectedLedger: "",
      ledgerOption: [],
      cfirstname: "",
      cmiddlename: "",
      clastname: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      area: "",
      areaOption: [],
      cityOption: [],
      pincode: "",
      dateofbirth: moment(new Date()).format("DD/MM/yyyy"),
      mobileNo: "",
      email: "",
      adharno: "",
      adharnoValidate: true,
      panno: "",
      pannoValidate: true,
      emailsend: "",
      nominee: "",
      nomineeRelationship: "",
      nomineephone: "",
      nomineePhone: "",
      noofinstallment: "",
      installments: [],
      ifEdit: false,
      bookingtype: "",
      noChequeField: false,
      selecttype: "",
      paymenttermday: "",
      userData: "",
      userName: "",
      flag: false,
      submitDisable: false,
      dashboard: false,
      fromState: "",
      stateOptions: [],
      StateData: [],
      customerOption: [],
      nomineedob: moment(new Date()).format("DD/MM/yyyy"),
      sendemail: "",
      sendwhatsapp: "",
      sendsms: "",
      popUpVisible: false,
      userDetails: "",
      agreementper: "",
      agreement: "",
      parent: "",
      bookingminvalueper: "",
      disremarks: "",
      bankLedgerOption: [],
      ifTreeUser: false,
      testplotvalue: "",
      isMobile: false,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      bank: "",
      multiPlots: [],
      errorMsg: "",
      loginData: {},
      emiOptions:[],
      bookingOptions: [],
      extraCustomers: [
        {
          customer: '',
          email:'',
          mobile:'',
          birthdate:'',
          panno:'',
          aadharcardno:'',
          nomineename:'',
          nomineerelation:'',
          nomineephone:'',
          nomineedob:'',
        }
      ],
      offerOptions: [],
      allOffer: [],
      offer: '',
      userType:''


    };
  },
  mounted() {
    const rights = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      rights.map((item) => {
        if (item.modulename == "Sales") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/sales");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/sales");
          }
        }
      });
    }
    this.getState();
    this.getOffer()

    if (this.$route.query.userId) {
      this.asPerTreeData();
    }
    const data = localStorage.getItem("userData");
    this.loginData = JSON.parse(localStorage.getItem("userData"));
    this.userType= this.loginData.type
    this.userData = this.loginData.role;
    this.userName = this.loginData.name;
    this.userId = this.loginData.id;
    this.loginData.color='info'
    this.init();
    if (localStorage.getItem("plotData")) {
      this.getFollowupData();
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
    this.isMobile = window.innerWidth > 500;
  },
  methods: {
    handleClickBank(event) {
      this.qrcode = event.qrcode
    },
    async sendOtp(){
      this.$refs.simpleRules.validate().then(async (success) => {
        if(!success ||
          this.pannoValidate == false ||
          this.adharnoValidate == false){
            this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return

        }
      if (this.bookingtype == "Part Payment") {
          let total = parseFloat(this.firstpayment);
          this.installments.map((item) => {
            total += parseFloat(item.installment_amount);
          });
          if (parseFloat(total.toFixed(2)) !== parseFloat(this.totalPayment)) {
            part = false;
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Installmant amount should be equal to total payment",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          return
        }
        if (
          success &&
          this.pannoValidate == true &&
          this.adharnoValidate == true
        ) {
          const reportauthority = {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}/sendotp`,
            data: {
              mobile: `${this.cfirstname.mobile}`,
              email: `${this.cfirstname.email}`,

            },
          };
          await axios(reportauthority).then(()=>{})
          this.otpModal=true
        }
      })


    },
    async handleOtp(){
      const reportauthority = {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}/sendotp`,
            data: {
              mobile: `${this.cfirstname.mobile}`,
            },
          };
          await axios(reportauthority).then((res)=>{
            if(res.data.success){
              this.otpModal=false
              this.font-weight-bolder()
            }else{
              this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Invalid OTP",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            }
          })
    },
    calcFirstpaymentMulti() {
      if (this.firstpayment > this.totalPayment) {
        this.firstpayment = this.totalPayment;
      }
      this.remainingPayment = this.totalPayment - this.firstpayment;
      if (this.selectedPaymentType == "Down Payment") {
        let amount = 10000;
        if (amount > parseFloat(this.firstpayment)) {
          this.errorMsg =
            "Amount must be in 5 figure";
        } else {
          this.errorMsg = "";
        }
        // let amount = 0;
        // this.multiPlots.map((item) => {
        //   amount += (item.totalpayment * 25) / 100;
        // });
        // if (amount > parseFloat(this.firstpayment)) {
        //   this.errorMsg =
        //     "Amount should be greater than and equal to " + amount;
        // } else {
        //   this.errorMsg = "";
        // }
      } else if (this.selectedPaymentType == "Token") {
        let amount = 10000 ;
        if (amount > parseFloat(this.firstpayment)) {
          this.errorMsg =
            "Amount must be in 5 figure";
        } else {
          this.errorMsg = "";
        }
      }
    },
    discountChange(e, index) {
      this.totalPayment = 0;
      this.remainingPayment = 0;
      if (!e) {
        e = 0;
        this.multiPlots[index].discount = 0;
      }
      this.multiPlots[index].totalpayment =
        parseFloat(this.multiPlots[index].totalamount) - (this.multiPlots[index].discount);
      this.multiPlots.map((item) => {
        this.totalPayment += parseFloat(item.totalpayment);
        this.remainingPayment += parseFloat(item.totalpayment);
      });
      this.remainingPayment -= this.firstpayment ? this.firstpayment : 0;
    },
    handleMultiPlot(e) {
      if (e) {
        this.totalPayment = 0;
        this.remainingPayment = 0;
        this.multiPlots = [];
        e.map((item) => {
          this.plotObj[item.id]=item.id
          this.multiPlots.push({
            plotid:item.id,
            plotrate: this.selecttype == 'Franchise' || this.userData== 'franchise'? item.franchiserate:item.rateperyd,
            plotareasqyard: item.plotareayd,
            plotareasqfeet: item.plotareasqfeet,
            discount: 0,
            disremarks: "",
            plotvalue: this.selecttype == 'Franchise' || this.userData== 'franchise' ? item.plotValueFr :item.plotValue,
            unitname: item.unitname,
            plotno: item.plotno,
            totalpayment: this.selecttype == 'Franchise' || this.userData== 'franchise' ? item.plotAmountFr:item.plotamount,
            totalamount:  this.selecttype == 'Franchise' || this.userData== 'franchise' ? item.plotAmountFr:item.plotamount,
            installmentsdate: moment(new Date()).format('DD/MM/yyyy'),
            noofinstallment:0,
            monthgap:0,
            installmentamt:0,
            installments:[{
                installment_date:moment(new Date()).format('DD/MM/yyyy'),
                installment_amount:0,
                remarks:'',
            }]
          });
          this.totalPayment += parseFloat(item.plotamount);
          this.remainingPayment += parseFloat(item.plotamount);


        });
        this.remainingPayment -= this.firstpayment ? this.firstpayment : 0;
      }
    },
     handleExtraCustomer(e, item) {
      if (e) {
        let array = this.extraCustomers.filter(item => item.customer && (item.customer.id == e.id))
        if (this.cfirstname && this.cfirstname.id !== e.id && array.length == 1) {
          item.email = e.email
          item.mobile = e.mobile
          item.birthdate = e.birthdate
          item.panno = e.panno
          item.aadharcardno = e.aadharcardno
          item.nomineename = e.nomineename
          item.nomineerelation = e.nomineerelation
          item.nomineephone = e.nomineephone
          item.nomineedob = e.nomineedob
        }
        else {
          item.customer = null
          item.email = ''
          item.mobile = ''
          item.birthdate = ''
          item.panno = ''
          item.aadharcardno = ''
          item.nomineename = ''
          item.nomineerelation = ''
          item.nomineephone = ''
          item.nomineedob = ''
        }
      }
      else {
        item.customer = null
        item.email = ''
        item.mobile = ''
        item.birthdate = ''
        item.panno = ''
        item.aadharcardno = ''
        item.nomineename = ''
        item.nomineerelation = ''
        item.nomineephone = ''
        item.nomineedob = ''
      }
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    async getBankLedger() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/bankledger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.bankLedgerOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async asPerTreeData() {
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.$route.query.userId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        const details = response.data.data[0].role;
        this.treeUserRole =
          details == "channelpartner"
            ? "ChannelPartner"
            : details == "franchise"
            ? "Franchise"
            : "Employee";
        this.ifTreeUser = true;
        this.selecttypee(this.treeUserRole);
        this.userInfo(this.$route.query.userId);
        this.selectedTeam = {
          name: response.data.data[0].name,
          id: this.$route.query.userId,
        };
      });
    },
    clickMinValuePer() {
      this.bookingminvalue =
        (this.bookingminvalueper * this.totalPayment) / 100;
      this.firstpayment = parseFloat(this.bookingminvalue.toFixed(2));
      this.bookingminvalue = parseFloat(this.bookingminvalue.toFixed(2));
      this.remainingPayment = parseFloat(
        (this.totalPayment - this.firstpayment).toFixed(2)
      );
    },
    clickMinValue() {
      this.bookingminvalueper =
        (this.bookingminvalue * 100) / this.totalPayment;
    },
    clickAgreementPer() {
      this.agreement = (this.agreementper * this.totalPayment) / 100;
      this.installmentamt = this.totalPayment - this.agreement;
    },
    clickAgreement() {
      this.agreementper = (this.agreement * 100) / this.totalPayment;
      this.installmentamt = this.totalPayment - this.agreement;
    },
    async userInfo(e) {
      let id = null;
      if (e.id) {
        id = e.id;
      } else {
        id = e;
      }
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        this.userDetails = response.data.data[0];
        if (this.userDetails.role !== "admin") {
        }
        this.getCustomer(this.userDetails.id);
      });
    },

    closePopUp() {
      this.popUpVisible = false;
    },
    handlePopUp() {
      if (this.selectedTeam || this.selecttype == "Direct") {
        this.popUpVisible = true;
      } else {
        this.$swal({
          title: "Warning",
          timer: 5000,
          text: "Plese Select User !",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    handleApprove() {
      this.active = 0;
    },
    async getCustomer(id) {
      const data = {
        assignto: id,
      };
      const requestoption = {
        method: "POST",
        url: `${this.baseApi}/getcustomerassignby`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
      };
      await axios(requestoption).then((response) => {
        this.customerOption = response.data.data;
      });
    },
    async getCustomerDetails(e) {
      if (e) {
        const requestoption = {
          method: "GET",
          url: `${this.baseApi}/getusersbyid/${e.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        };
        await axios(requestoption).then((response) => {
          const details = response.data.data[0];
          this.handleCustomer(details);
        });
      }
    },
    handleCustomer(details) {
      this.email = details.email;
      this.mobileNo = details.mobile;
      this.dateofbirth =
        details.birthdate == "00/00/0000" || details.birthdate == null
          ? null
          : details.birthdate;
      this.pincode = details.curpincode;
      this.area = details.curarea;
      this.city = details.curcity;
      this.state = details.curstate;
      this.adharno = details.aadharcardno;
      this.panno = details.panno;
      this.nominee = details.nomineename;
      this.nomineerelationship = details.nomineerelation;
      this.nomineephone = details.nomineephone;
      this.nomineedob = details.nomineedob;
    },

    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getUserById(id) {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getusersbyid/${id}`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.selectedTeam = response.data.data[0];
          if (this.selectedTeam.role == "channelpartner") {
            this.selecttype = "ChannelPartner";
          } else if (this.selectedTeam.role == "franchise") {
            this.selecttype = "Franchise";
          } else if (this.selectedTeam.role == "employee") {
            this.selecttype = "Employee";
          } else if (this.selectedTeam.role == "admin") {
            this.selecttype = "Direct";
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    selecttypee(e) {
      this.selecttype = e;
      this.selectedTeam = null;
      if (this.selecttype == "Direct") {
        this.selectedTeam = {
          id: this.userId,
        };
        this.userInfo(this.userId);
        this.getCustomer(this.userId);
      }
    },
    async handleMultiFileChange(e, type, index) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.bankreceipt =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    handelClickBooking(e) {
      this.agreementper = "";
      this.agreement = "";
      this.bookingtype = e;
      if (!e || e == 'Full Payment') {
        this.multiPlots.map((item) => {
          item.installments=[]
        })
      }
      this.handlePartPayment();
      this.$forceUpdate();
    },
    handlePartPayment() {

      this.multiPlots.map((item)=>{
        item.installments = [
        {
          installment_date: moment(new Date()).format("DD/MM/YYYY"),
          installment_amount: "",
          remarks: "",
          type: "Part Payment",
        },
      ];
      })
    },
    getFollowupData() {
      const plotData = JSON.parse(localStorage.getItem("plotData"));
      this.dashboard = plotData.dashboard;
      this.selectedProject = plotData.project;
      this.selectedPlot = plotData.plot;
      this.cfirstname = plotData.client ? plotData.client : {};

      this.email = this.cfirstname.email;
      this.mobileNo = this.cfirstname.mobile;
      this.birthdate = this.cfirstname.birthdate;
      this.pincode = this.cfirstname.pincode;
      this.area = this.cfirstname.area;
      this.city = this.cfirstname.city;
      this.state = this.cfirstname.state;

      plotData.client && this.getUserById(this.cfirstname.assignto);

      this.getPlot(plotData.projectid);
      this.getPlotById(plotData.plotid);
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getsalesbyid/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      // this.installmentsdate = item.installmentsdate
      //   ? new Date(item.installmentsdate.split("/").reverse().join("-"))
      //   : "";
      this.installmentsdate = moment(item.installmentsdate).format(
        "DD/MM/yyyy"
      );
      this.selecttype = item.branch;
      this.selectedTeam = this.getEmp(item.team);
      this.bookingdate = item.bookingdate;
      this.bookingtype = item.booktype;
      this.selectedProject = this.getProject(item.project);
      // this.phase = item.phase;
      this.selectedPlot = this.getPlotById(item.plotno);
      this.plotrate = item.plotrate;
      this.plotAreaYard = item.plotareasqyard;
      this.plotAreaFeet = item.plotareasqfeet;
      this.discount = item.discount;
      this.disremarks = item.disremarks;
      this.plotvalue = item.plotvalue;
      this.paymenttermday = item.paymenttermday;
      this.selectedPaymentType = item.paymenttype;
      this.selectedPaymentThrough = item.paymentthrough;
      this.totalPayment = item.totalpayment;
      this.firstpayment = item.firstpayment;
      this.remainingpayment = item.remainingpayment;
      this.monthgap = item.monthgap;
      this.installmentamt = item.installmentamt;
      this.noofinstallment = item.noofinstallment;
      this.ledger = item.ledger;
      this.title = item.title;
      this.cfirstname = item.cfirstname;
      this.refno = item.refno;
      this.bankreceipt = item.bankreceipt;
      this.bankdate = item.bankdate;
      this.bankremarks = item.bankremarks;
      // this.cmiddlename = item.cmiddlename
      // this.clastname = item.clastname
      this.address = item.address;
      this.country = item.country;
      this.state = item.state;
      this.city = item.city;
      this.area = item.area;
      this.pincode = item.pincode;
      this.dateofbirth = item.dateofbirth;
      this.mobileNo = item.mobileno;
      this.email = item.email;
      this.adharno = item.adharno;
      this.panno = item.panno;
      this.emailsend = item.emailsend;
      this.nominee = item.nominee;
      this.nomineerelationship = item.nomineerelationship;
      this.nomineephone = item.nomineephone;
      this.nomineedob = item.nomineedob;
      this.selectedtitle = item.title;
      this.bankName = item.bankname;
      this.bankbranch = item.bankbranch;
      this.bookingminvalue = item.bookingminvalue;
      this.chequeNo = item.chequeno;
      this.installments = item.saleitems;
      this.status = item.status;
      this.active = item.active;
      this.remarks = item.remarks;
      this.agreementper = item.agreementper;
      this.agreement = item.agreement;
      this.bookingminvalueper = item.bookingminvalueper;
      this.sendemail = item.sendemail == 1;
      this.sendsms = item.sendsms == 1;
      this.sendwhatsapp = item.sendwhatsapp == 1;
      // this.handleBank(item.bank_id)

      // this.clickPincode()
      this.handleCity();
      this.handleArea();
      this.userInfo(item.team);
      this.handleSelectPayment(item.paymentthrough);
    },
    handleSelectPayment(e) {
      const name = e;
      if (name == "By Cheque") {
        this.chequeField = true;
      } else {
        this.chequeField = false;
      }
      if (name !== "Other" && name !== "By Cheque") {
        this.noChequeField = true;
      } else {
        this.noChequeField = false;
      }
       if (name == 'By UPI') {
        this.handleClickBank(this.bank)
      }
    },
    handleImage() {
      this.visible = true;
    },
    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area.Pincode;
      }
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
    },
    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      this.cityOption = [];
      this.areaOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.adharno.length == 4
        ? (this.adharno = this.adharno.concat(" "))
        : this.adharno;
      this.adharno.length == 9
        ? (this.adharno = this.adharno.concat(" "))
        : this.adharno;
      if (regex.test(this.adharno) == true) {
        this.adharnoValidate = true;
      } else {
        this.adharnoValidate = false;
      }
    },

    onClickBack() {
        this.$router.push("/crm/sales");
    },
    submitForm() {
      let part = true;
      this.$refs.simpleRules.validate().then(async (success) => {
         let extraCustomers = this.extraCustomers.filter((item) => item.cfirstname);
      extraCustomers = extraCustomers.map((item) => item.cfirstname.id);

        this.multiPlots.map((item) => {
          item.installments.map((item)=>{
            item.installment_date = moment(item.installments,'DD/MM/yyyy').format('YYYY-MM-DD')
          })
          item.plotno = item.plotid
          item.team = this.selectedTeam.id
          item.project = this.selectedProject.id
          item.branch = this.selecttype
          item.paymenttype = this.selectedPaymentType
          item.paymentthrough = this.selectedPaymentThrough
          item.bookingdate = moment(this.bookingDate,'DD/MM/YYYY').format('YYYY-MM-DD')
          item.booktype = this.bookingtype
          item.firstpayment = this.firstpayment / this.multiPlots.length,
          item.remainingpayment = parseFloat(item.totalpayment) - parseFloat(item.firstpayment)
          item.title = this.selectedtitle
          item.cfirstname = this.cfirstname.name
          item.cfirstid = this.cfirstname.id
          item.address = this.address
          item.country = this.country
          item.area = this.area
          item.state = this.state
          item.city = this.city
          item.pincode = this.pincode
          item.dateofbirth = moment(this.dateofbirth,'DD/MM/YYYY').format('YYYY-MM-DD')
          item.mobileno = this.mobileNo
          item.email = this.email
          item.adharno = this.adharno
          item.panno = this.panno
          item.emailsend = this.emailsend
          item.nominee = this.nominee
          item.nomineerelationship = this.nomineerelationship
          item.nomineephone = this.nomineephone
          item.refno = this.refno
          item.bankreceipt = this.bankreceipt
          item.bankdate = this.bankdate
          item.bankbranch = this.bankbranch
          item.bankremarks = this.bankremarks
          item.nomineedob = this.nomineedob
          item.paymenttermday = this.paymenttermday
          item.bankname = this.bankName
          item.chequeno = this.chequeNo
          item.chequedate = moment(this.chequedate,'DD/MM/YYYY').format('YYYY-MM-DD')
          item.bookingminvalue = this.bookingminvalue / this.multiPlots.length
          item.status = this.status
          item.active = this.active
          item.remarks = this.remarks
          item.agreementper = this.agreementper
          item.agreement = this.agreement / this.multiPlots.length
          item.bookingminvalueper = this.bookingminvalueper
          item.sendemail = this.sendemail == 1
          item.sendsms = this.sendsms == 1
          item.sendwhatsapp = this.sendwhatsapp == 1
          item.data = item.installments
          item.bank_id = this.bank ? this.bank.id : ''
          item.offer_id= this.offer?this.offer.id:'',

          delete item.totalamount
        })
        if (
          success == false ||
          this.pannoValidate == false ||
          this.adharnoValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (this.bookingtype == "Part Payment") {

          this.multiPlots.map((item)=>{
            let total = parseFloat(this.firstpayment/this.multiPlots.length);
            item.data.map((inner) => {
              total += parseFloat(inner.installment_amount);
            });
            if (parseFloat(total.toFixed(2)) !== parseFloat(item.totalpayment)) {
              part = false;
              this.$swal({
                title: "Warning",
                timer: 5000,
                text: "Installmant amount should be equal to total payment",
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
          }
        if (
          success &&
          this.pannoValidate == true &&
          this.adharnoValidate == true &&
          part == true
        ) {
          let arr=[]
          let errorPlot=[]
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
            await axios({
              method: "post",
              url: `${baseApi}/save-multiple`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              data: JSON.stringify(this.multiPlots),
            })
              .then((json) => {
                  this.$swal({
                  title: "Submited",
                  text:"Submitted SuccessFully",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                  this.submitDisable = false;
                  this.$router.push("/crm/sales");
              })
              .catch((error) => {
                this.submitDisable = false;
                const code = error.toString().includes("409");
                let message = "";
                if (code) {
                  message = error.response.data.message.original.email[0];
                }
                this.$swal({
                  title: "Error!",
                  text: message ? `${message}` : error,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              });

        }
      });
    },
    async init() {
      this.getTeam();
      if (this.userData == "channelpartner") {
        this.downlineCP();

        this.selecttype = "ChannelPartner";
      } else if (this.userData == "employee") {
        this.selectedTeam = {
          name: this.userName,
          id: this.userId,
        };
        this.userInfo(this.selectedTeam);
      } else if (this.userData == "franchise") {
        this.selectedTeam = {
          name: this.userName,
          id: this.userId,
        };
        this.userInfo(this.selectedTeam);
      } else {
        this.getChannelPartner();
      }
      this.getFranchise();
      this.getProject();
      this.getBankLedger();
      // this.getLedger()
      // this.getCustomer();
    },
    async downlineCP() {
      this.teamOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.loginData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.CPOption=[this.loginData]
          response.data.data.map((item)=>{
            item.color='success'
            this.CPOption.push(item)
            if(item.children.length>0){
              this.handleChild(item.children)
            }
          })
        })
        .catch((error) => console.log(error, "error"));
    },
    handleChild(childs){
      childs.map((item)=>{
        this.CPOption.push(item)
        if(item.children.length>0){
          this.handleChild(item.children)
        }
      })
    },
    getEmp(id) {
      // this.selecttype = 'ChannelPartner'
      if (this.selecttype == "ChannelPartner") {
        this.getChannelPartner(id);
      } else if (this.selecttype == "Franchise") {
        this.getFranchise(id);
      } else if (this.selecttype == "Employee") {
        this.getTeam(id);
      } else {
        return {
          id: this.userId,
        };
      }
    },
    async getTeam(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.teamOption = response.data.data;
          if (id !== undefined) {
            this.teamOption.map((item) => {
              if (item.id == id) {
                this.selectedTeam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelPartner(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.CPOption = response.data.data;
          if (id !== undefined) {
            this.CPOption.map((item) => {
              if (item.id == id) {
                this.selectedTeam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getFranchise(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.FranchiseOption = response.data.data;
          if (id !== undefined) {
            this.FranchiseOption.map((item) => {
              if (item.id == id) {
                selectedTeam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProject() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlot(id) {
      this.bookingOptions = []
      this.offerOptions = []
      this.offerOptions = this.allOffer.filter((item) => item.project_id.split(',').indexOf(id.toString()) !== -1)
      if (this.selectedProject.bookingtype) {
        let obj = JSON.parse(this.selectedProject.bookingtype)
        if (obj.pp) {
          this.bookingOptions.push('Part Payment')
        }
        if (obj.fp) {
          this.bookingOptions.push('Full Payment')
        } if (obj.emi) {
          this.bookingOptions.push('EMI')
        }
      }
      let emiArr = this.selectedProject.emi ? JSON.parse(this.selectedProject.emi) : []
      this.emiOptions = []
      emiArr.map((item, index) => {
        if (item.fromMonth < item.toMonth) {
          for (let month = item.fromMonth; month <= item.toMonth; month++) {
            this.emiOptions.push({ month: month, rate: item.rate })
          }
        }
      })

      this.qrcode = this.selectedProject.qrcode;
      this.handleBank(this.selectedProject.bank_id);
      this.projectId = id;
      this.visible = true;
      this.getAttachment(id);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/plotno`,
        data: JSON.stringify({
          project_id: id,
        }),
      };
      await axios(requestOptions)
        .then((response) => {
          this.plotOption = response.data.data;
          if (id !== undefined) {
            this.plotOption.map((item) => {

            });

          }
        })
        .catch((error) => console.log(error, "error"));
    },

    async getOffer() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/get-offers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allOffer = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAttachment(id) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getattachment`,
        data: JSON.stringify({
          project_id: id,
        }),
      };
      await axios(requestOptions)
        .then((response) => {
          this.phaseImage.source = response.data.data
            ? response.data.data
            : image;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleBank(id) {
      this.bankLedgerOption.map((item) => {
        if (item.id == id) {
          this.bank = item;
        }
      });
    },
    addRow(index) {
      let total = parseFloat(this.firstpayment/this.multiPlots.length);
      this.multiPlots[index].installments.map((item) => {
        total += parseFloat(item.installment_amount);
      });
      if (total < parseFloat(this.multiPlots[index].totalpayment)) {
        this.multiPlots[index].installments.push({
          installment_date: moment(new Date()).format("DD/MM/yyyy"),
          installment_amount: "",
          remarks: "",
          type: "Part Payment",
        });
      } else {
        if (total.toFixed(2) > parseFloat(this.multiPlots[index].totalpayment).toFixed(2)) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Installmant amount should be equal to total payment",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Installmant amount is equal to total payment",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    deleteRow(index,installIndex) {
      this.multiPlots[index].installments.splice(installIndex, 1);
    },
    handleInstallment(e, name, index) {
      console.log(this.multiPlots,index)
      // if (name == "noofinstallment") {
      this.multiPlots[index].installments = [];
      this.multiPlots[index].noofinstallment = e.month
      this.multiPlots[index].emirate = e.rate
      // if (e == "1") {
      //   this.installments.push({
      //     installment_date: moment(new Date()).format("DD/MM/yyyy"),
      //     installmentamt: this.installmentamt,
      //     remarks: "",
      //   });
      // } else
      //  {
      // for (let index = 0; index < parseInt(e); index++) {
      //   this.installments.push({
      //     installment_date: moment(new Date()).format('DD/MM/yyyy'),
      //     installmentamt: this.installmentamt,
      //     remarks: ''
      //   })
      // }
      this.multiPlots[index].installments.map((item) => {

        item.installment_amount = isNaN(
          parseFloat(this.multiPlots[index].installmentamt) / parseFloat(this.noofinstallment)
        )
          ? 0
          : parseFloat(this.multiPlots[index].installmentamt) /
            parseFloat(this.multiPlots[index].noofinstallment);
      });
      if (this.multiPlots[index].handlemonthgapname) {
        this.handlemonthgap('','',index);
      }
      // }
    },

    handlemonthgap(e,name,index1) {
      if (e == undefined) {
        e = this.handlemonthgapname;
      }
      this.handlemonthgapname = e;

      const data = [];
      this.multiPlots[index1].installments = [];
      let R = this.multiPlots[index1].emirate / 12 / 100
      this.multiPlots[index1].firstpayment = this.firstpayment / this.multiPlots.length,
      this.multiPlots[index1].remainingpayment = parseFloat(this.multiPlots[index1].totalpayment) - parseFloat(this.multiPlots[index1].firstpayment)
      console.log(this.multiPlots[index1].totalpayment);

      this.multiPlots[index1].installmentamt = ((this.multiPlots[index1].remainingpayment * R * ((1 + R) ** this.multiPlots[index1].noofinstallment)) / ((1 + R) ** this.multiPlots[index1].noofinstallment - 1)) * this.multiPlots[index1].noofinstallment
      this.multiPlots[index1].installmentamt = parseFloat(this.multiPlots[index1].installmentamt.toFixed(2))


      this.BetweenDate = new Date(this.multiPlots[index1].installmentsdate);
      for (let index = 0; index < parseInt(this.multiPlots[index1].noofinstallment); index++) {
        this.month = null;
        this.selectedMonth = null;
        this.selectedDay = null;
        this.yearr = null;
        this.selectedMonth = this.BetweenDate.getMonth();
        // this.selectedDay = this.installmentsdate.getFullYear()
        // console.log(this.selectedDay, 'selectedDay===')
        this.month =
          e * index + (this.selectedMonth > 12 ? this.selectedMonth : "");
        // for (let index1 = this.selectedDay; this.month > 12; index1++) {
        //   this.month = parseInt(this.month) - 12
        //   this.yearr = index1
        // }
        // console.log(this.month, 'month===')
        // console.log(this.yearr, 'month===')
        this.BetweenDate.setMonth(parseInt(this.month));
        // this.BetweenDate.setYear(this.yearr)

        data.push(moment(new Date(this.BetweenDate)).format("DD/MM/yyyy"));
      }
      data.map((item) => {
        let amt = this.multiPlots[index1].installmentamt / this.multiPlots[index1].noofinstallment
        this.multiPlots[index1].installments.push({
          installment_date: item,
          installment_amount: parseFloat(amt.toFixed(2)),
          remarks: "",
        });
      });
    },
    handleinstallmentamt(e, name,index) {
      // this.installments.installmentamt = []
      this.multiPlots[index].installments.map((item) => {
        item.installment_amount = e / this.multiPlots[index].noofinstallment;
        this.multiPlots[index].installmentamt = e;
      });
    },
  },
};
</script>

<style>
.bankDetails {
  padding: 20px 10px;
  border: 1px solid #d8d6de;
  border-radius: 10px;
}
.bankDetailsChild{
  padding:10px
}
</style>

